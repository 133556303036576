import React, { useEffect } from "react";
import { CirclePicker } from "react-color";
import {PICKER_DEFAULT_COLOR, PICKER_COLORS} from "../../config"

// カラーコードを #NNNNNN 形式に整形する
export function formatColorCode(input) {
  const cleanedInput = input.replace(/[^A-Fa-f0-9]/g, '');

  if (cleanedInput.length === 3) {
    const expandedColor = cleanedInput
      .split('')
      .map((char) => char + char)
      .join('');
    return '#' + expandedColor.toUpperCase();
  }
  if (cleanedInput.length !== 6) {
    return PICKER_DEFAULT_COLOR;
  }
  return '#' + cleanedInput.toUpperCase();
}

const EventColorPicker = (props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [currentColor, setCurrentColor] = React.useState(formatColorCode(props.colorCode));

  // デフォルトカラーをセット
  useEffect(() => {

    const {colorRef} = props;
    if (colorRef.current) {
      colorRef.current.value = currentColor;
    }
  })

  // スタイル
  const stylePickerButton = {
    display: "block",
    background: currentColor,
    width: "36px",
    height: "100%",
    marginLeft: "8px",
    border: "3px solid #aaa",
    borderRadius: "5px",
  };
  const stylePicker = {
    display: isOpen ? "block" : "none",
    height: isOpen ? "auto" : 0,
    height: "auto",
    position: "absolute",
    top: "48px",
    right: 0,
    background: "white",
    zIndex: 2,
    border: "0px solid rgba(0, 0, 0, 0.25)",
    boxShadow: "rgba(0, 0, 0, 0.25) 0px 1px 4px",
    borderRadius: "4px",
    padding: "1rem",
    borderRadius: "10px",
  };

  const toggleOpenPicker = () => {
    setIsOpen(!isOpen);
  };

  const toggleColor = (color) => {
    const {colorRef} = props
    if (colorRef.current) {
      colorRef.current.value = color.hex;
      setCurrentColor(color.hex);
    }
    setIsOpen(false);
  };

  const Picker = () => {
    return (
      <div>
        <button style={stylePickerButton} onClick={() => toggleOpenPicker()}></button>
        <div style={stylePicker}>
          {isOpen && <CirclePicker
            onChange={toggleColor}
            color={currentColor}
            colors={PICKER_COLORS}
          />}
        </div>
      </div>
    );
  };

  return <Picker />;
};

export default EventColorPicker;