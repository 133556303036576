import { ViewTypes } from "react-big-scheduler";

// ドメイン名
export const BACKEND_DOMAIN_NAME = 'schedule-backend.f-koken.sysdsv.com';
export const FM_DOMAIN_NAME = 'f-koken.sysdsv.com';
export const FM_ACCOUNT_NAME = 'fujimi';
export const FM_ACCOUNT_PASSWORD = 'fujimi2711';
export const FM_FILENAME_QUALITY = '品質管理システム'; //CHANGED UPDATE 2023/07/04 #25

// API接続先
let URL = 'http://localhost:8081';
if (process.env.NODE_ENV === 'production') {
  URL = `https://${BACKEND_DOMAIN_NAME}`;
}

export const API_URL = URL;

// カレンダー設定
export const SCHEDULER_DATA_CONFIG = {
  schedulerWidth: '100%',
  besidesWidth: 20,
  schedulerMaxHeight: 0,
  // CHANGED:UPDATE 2023/08/29 #38
  tableHeaderHeight: 78,

  // agendaResourceTableWidth: 160,
  // agendaMaxEventWidth: 100,

  // dayResourceTableWidth: 160,
  // weekResourceTableWidth: '16%',
  monthResourceTableWidth: 200,
  // quarterResourceTableWidth: 160,
  // yearResourceTableWidth: 160,
  customResourceTableWidth: 200,

  // dayCellWidth: 30,
  // weekCellWidth: '12%',
  monthCellWidth: 70,
  // quarterCellWidth: 80,
  // yearCellWidth: 80,
  customCellWidth: 70,
  defaultCellWidth:70,

  // dayMaxEvents: 99,
  // weekMaxEvents: 99,
  monthMaxEvents: 99,
  // quarterMaxEvents: 99,
  // yearMaxEvents: 99,
  customMaxEvents: 99,

  eventItemHeight: 30,
  eventItemLineHeight: 30,
  // nonAgendaSlotMinHeight: 0,
  // dayStartFrom: 0,
  // dayStopTo: 23,
  defaultEventBgColor: '#80C5F6',
  selectedAreaColor: null,
  // nonWorkingTimeHeadColor: '#999999',
  // nonWorkingTimeHeadBgColor: '#fff0f6',
  // nonWorkingTimeBodyBgColor: '#fff0f6',
  // summaryColor: '#666',
  // summaryPos: 1,
  groupOnlySlotColor: '#F8F8F8',

  startResizable: false,
  endResizable: false,
  movable: true,
  creatable: true,
  crossResourceMove: true,
  checkConflict: false,
  scrollToSpecialMomentEnabled: false,
  eventItemPopoverEnabled: false,
  calendarPopoverEnabled: true,
  recurringEventsEnabled: false,
  headerEnabled: false,
  displayWeekend: true,
  relativeMove: true,
  defaultExpanded: true,

  resourceName: "",
  taskName: 'タスク名',
  agendaViewHeader: '',
  addMorePopoverHeaderFormat: 'YYYY年M月D日 dddd',
  eventItemPopoverDateFormat: 'M月D日',
  nonAgendaDayCellHeaderFormat: 'HH:mm',
  nonAgendaOtherCellHeaderFormat: 'YYYY/MM/DD|ddd',

  // minuteStep: 30,

  views: [
    // {viewName: '日', viewType: ViewTypes.Day, showAgenda: false, isEventPerspective: false},
    // {viewName: '週', viewType: ViewTypes.Week, showAgenda: false, isEventPerspective: false},
    {viewName: '月次', viewType: ViewTypes.Month, showAgenda: false, isEventPerspective: false},
    // {viewName: '年', viewType: ViewTypes.Year, showAgenda: false, isEventPerspective: false},
    {viewName: '週次', viewType: ViewTypes.Custom, showAgenda: false, isEventPerspective: false},
  ]
};

//CHANGED ADD 2023/07/04 #25
export const FM_CHECKLIST_SHORTCUT = {
  scriptName : "チェックリスト",
};

export const PICKER_COLORS = ["#F66503", "#FCE856", "#77DBB1", "#3FCD84", "#8ECFF8", "#E8104B", "#F58AA6", "#9624EE", "#308FE4", "#AAB8C1"];
export const PICKER_DEFAULT_COLOR = PICKER_COLORS[1];